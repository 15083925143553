.success_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 600px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.success_container > img {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.success_container > p {
    font-size: 12px;
}


.info_screen {
    margin-top: 40px;
    height: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 5px;
    position: relative;
}

.iframe-container { 
    position: relative;
     width: 100%;
     max-width: 100%;
     /* Prevents it from exceeding the parent container's width */ padding-bottom: 56.25%;
     /* 16:9 aspect ratio */ height: 0;
     flex: 1;
     /* Ensure it grows to take up the available space */ 
     display: flex;

     margin-top: 5px;
     margin-bottom: 5px;
} 

.iframe-container iframe { position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;

}


@media (max-width: 1000px) {
    .info_screen {
        grid-template-columns: 1fr;

    }
    .info_images {
        display: none;

    }
    .info_step > h2 {
        transform: translateY(0)!important;
    }
}

.info_content {
    padding: 20px;
}

.info_content > h1 {
    text-align: center;
    font-size: 22px;
}

.info_content_image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.info_content_image > img {
    width: 70px;
}

.info_step > h2 {
    font-size: 16px;
}

.info_step_title {
    display: flex;
    align-items: flex-start;
}

.info_step_title > div {
    width: 9px;
    height: 9px;
    min-width: 9px;
    min-height: 9px;
    background-color: #26203E;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 15px;
}

.info_step_title > h3 {
    font-size: 16px;
    margin-top: 7px;
    margin-bottom: 7px;
    line-height: 25px;
}

.info_step_sub_title {
    display: flex;
    align-items: flex-start;
    padding-left: 20px;
    margin-bottom: 7px;
}

.info_step_sub_title > div {
    width: 9px;
    height: 9px;
    min-width: 9px;
    min-height: 9px;
    background-color: white;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid #26203E;
    margin-top: 5px;
}

.info_step_sub_title > p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
}

.info_step > p {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 30px;
}

.info_back_btn {
    position: absolute;
    bottom: 0;
    left: 15px;
    width: 50px;
    cursor: pointer;
    color: rgba(19,15,58,0.4);
}

@media (max-width: 1000px) {
    .info_back_btn {
        bottom: -10px;
    }
}

.info_back_btn:hover {
    color: rgba(19,15,58,0.7);
}

.info_images {
    min-height: 730px;
    background-position: center;
    background-size: contain!important;
    background-repeat: no-repeat;
    background-color: #F4F4F4;
}

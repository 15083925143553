.placeholder-item {
    border-radius: 4px;
}
.loading_container {
    position: relative;
    overflow: hidden;
    background-color: #eee7e7;
    border-radius: 2px;
}

.loading_title {
    width: 100%;
}
.loading_container::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #ddcfcf 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
/* Grid layout */
.loading_box {
    margin: 20px;
    border-radius: 10px;
    padding: 20px;
}
.loading_sec-1 {
    grid-area: sec-1;
}
.loading_sec-2 {
    grid-area: sec-2;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.loading_sec-3 {
    grid-area: sec-3;
}
.loading_sec-4 {
    grid-area: sec-4;
    position: relative;
}
.loading_sec-5 {
    grid-area: sec-5;
}
.loading_sec-6 {
    grid-area: sec-6;
}
.loading_sec-7 {
    grid-area: sec-7;
}
.loading_grid_container {
    height: 1800px;
    display: grid;
    grid-template-columns: 36vw 36vw 27vw;
    grid-template-rows: auto;
    grid-template-areas:
    "sec-1 sec-2 sec-3"
    "sec-4 sec-4 sec-3"
    "sec-5 sec-5 sec-6"
    "sec-7 sec-7 sec-7";
}
.loading_chart {
    display: flex;
    position: absolute;
    left: 20px;
    bottom: 20px;
}
@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}
@media (max-width: 1150px) {
    .loading_grid_container {
        height: 2800px;
        display: grid;
        grid-template-columns: 100vw;
        grid-template-rows: auto;
        grid-template-areas:
    "sec-1"
    "sec-2"
    "sec-3"
    "sec-4"
    "sec-5"
    "sec-6"
    "sec-7";
    }
}

.table_loading {
    height: 700px;
}

header {
    box-sizing: border-box;
    width: 100%;
    height: 90px;
    padding: 12px;
    display: flex;
}

header > img {
    width: 150px;
}

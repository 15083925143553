@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

/*Common styles*/

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  padding: 1px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  border-radius: 50%;
  margin-right: 10px;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: white;
  border: 7px solid rgba(19, 15, 48, 0.65);
}

.sub_label {
  max-width: 500px;
  font-size: 12px;
  line-height: 17px;
}

.list_item {
  max-width: 500px;
  font-size: 12px;
  line-height: 8px;
  padding-left: 5px;
}

.row {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.end {
  display: flex;
  justify-content: flex-end;
}

a {
  color: white;
  text-decoration: none;
}

h2 {
  font-size: 16px;
  color: #130F30;
}

h3 {
  font-size: 16px;
  line-height: 20px;
}

body {
  color: #130F30;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.error_page {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #ECECEF;
}
.error_page_box {
  position: relative;
  width: 70vw;
  padding-top: 30vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.error_page_title {
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 3rem;
}
.error_page_text {
  padding-top: 2rem;
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}
.error_page_box > div {
  position: relative;
  z-index: 2;
  margin-top: 3rem;
}
.error_btn {
  max-width: 305px;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.title_h3 {
  line-height: 1em;
  color: white;
}

.error_view {
  margin-top: -15px;
  padding: 0;
  background-color: #ECECEF;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.error_type {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error_type > h1 {
  font-size: 30vw;
  color: #e3e3e9;
}

.rdrDefinedRangesWrapper {
  display: none!important;
}

.rdrDateDisplayWrapper {
  background-color: transparent!important;
}

.rdrDateInput {
  -webkit-box-shadow: 0px 4px 5px 1px rgba(0,0,0,0.28);
  box-shadow: 0px 4px 5px 1px rgba(0,0,0,0.28);
}

.rdrDateDisplayItemActive {
  border-color: #130F30;
}

main {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 70px;
  background-image: url("img/bg.png");
  background-size: cover;
}

.main_container {
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1000px) {
  .main_container {
    grid-template-columns: 1fr;
  }

}

.main_container.full {
  grid-template-columns: 1fr;
  /* padding-top: 40px;
  padding-bottom: 40px; */
}

.main_form_header_text {
  font-size: 12px;
}

.main_header {
  height: 80px;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_btn_container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.button_placeholder {
  height: 30px;
  width: 150px;
  color: white;
  background-color: #FF5242;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_container {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  max-width: 500px;
}

.contact_container_title {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 850px) {
  .main_header {
    display: block;
    height: 100%;
  }
}

@media (max-width: 500px) {
  main {
    padding: 20px;
  }
}

.textarea_corporate {
  margin-top: 10px;
  width: 100%;
  max-width: 500px;
  resize: none;
}

.contact_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 500px;
}

.image_btn {
  font-family: Montserrat;
  font-weight: 600;
  color: rgba(19, 15,48,0.75);
  width: inherit;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 10%;
  height: 30px;
  border: 1px solid rgba(19, 15,48,0.45);
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width:890px){
  .image_btn {
    font-family: Montserrat;
    font-weight: 600;
    color: rgba(19, 15,48,0.75);
    width: inherit;
    font-size: 12px;
    position: initial;
    top: 0;
    right: 10%;
    height: 30px;
    border: 1px solid rgba(19, 15,48,0.45);
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.image_btn > img {
  width: 15px;
  margin-right: 3px;
}

.corporate_view {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.local_example_title > h4 {
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
}

.local_example_title > p {
    font-size: 10px;
    margin-bottom: 0;
    margin-left: 20px;
}

@media (max-width: 1100px) {
  .corporate_view {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 450px) {
  .contact_grid {
    grid-template-columns: 1fr;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
.date_range_container {
    position: absolute;
    bottom: 50px;
    background-color: white;
    border-radius: 3px;
    padding: 10px 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    width: 320px;
    max-height: 400px;
}

.date_wrapper {
    max-width: 100%!important;
    max-height: 100%!important;
}

.rdrDay {
    background: #ffffff!important;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

/*Common styles*/

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  padding: 1px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  border-radius: 50%;
  margin-right: 10px;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: white;
  border: 7px solid rgba(19, 15, 48, 0.65);
}

.sub_label {
  max-width: 500px;
  font-size: 12px;
  line-height: 17px;
}

.list_item {
  max-width: 500px;
  font-size: 12px;
  line-height: 8px;
  padding-left: 5px;
}

.row {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.end {
  display: flex;
  justify-content: flex-end;
}

a {
  color: white;
  text-decoration: none;
}

h2 {
  font-size: 16px;
  color: #130F30;
}

h3 {
  font-size: 16px;
  line-height: 20px;
}

body {
  color: #130F30;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.error_page {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #ECECEF;
}
.error_page_box {
  position: relative;
  width: 70vw;
  padding-top: 30vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.error_page_title {
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 3rem;
}
.error_page_text {
  padding-top: 2rem;
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}
.error_page_box > div {
  position: relative;
  z-index: 2;
  margin-top: 3rem;
}
.error_btn {
  max-width: 305px;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.title_h3 {
  line-height: 1em;
  color: white;
}

.error_view {
  margin-top: -15px;
  padding: 0;
  background-color: #ECECEF;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.error_type {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error_type > h1 {
  font-size: 30vw;
  color: #e3e3e9;
}

.rdrDefinedRangesWrapper {
  display: none!important;
}

.rdrDateDisplayWrapper {
  background-color: transparent!important;
}

.rdrDateInput {
  box-shadow: 0px 4px 5px 1px rgba(0,0,0,0.28);
}

.rdrDateDisplayItemActive {
  border-color: #130F30;
}

main {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 70px;
  background-image: url(/static/media/bg.e96c5b6d.png);
  background-size: cover;
}

.main_container {
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1000px) {
  .main_container {
    grid-template-columns: 1fr;
  }

}

.main_container.full {
  grid-template-columns: 1fr;
  /* padding-top: 40px;
  padding-bottom: 40px; */
}

.main_form_header_text {
  font-size: 12px;
}

.main_header {
  height: 80px;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_btn_container {
  display: flex;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.button_placeholder {
  height: 30px;
  width: 150px;
  color: white;
  background-color: #FF5242;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_container {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  max-width: 500px;
}

.contact_container_title {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 850px) {
  .main_header {
    display: block;
    height: 100%;
  }
}

@media (max-width: 500px) {
  main {
    padding: 20px;
  }
}

.textarea_corporate {
  margin-top: 10px;
  width: 100%;
  max-width: 500px;
  resize: none;
}

.contact_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 500px;
}

.image_btn {
  font-family: Montserrat;
  font-weight: 600;
  color: rgba(19, 15,48,0.75);
  width: inherit;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 10%;
  height: 30px;
  border: 1px solid rgba(19, 15,48,0.45);
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width:890px){
  .image_btn {
    font-family: Montserrat;
    font-weight: 600;
    color: rgba(19, 15,48,0.75);
    width: inherit;
    font-size: 12px;
    position: initial;
    top: 0;
    right: 10%;
    height: 30px;
    border: 1px solid rgba(19, 15,48,0.45);
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.image_btn > img {
  width: 15px;
  margin-right: 3px;
}

.corporate_view {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.local_example_title > h4 {
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
}

.local_example_title > p {
    font-size: 10px;
    margin-bottom: 0;
    margin-left: 20px;
}

@media (max-width: 1100px) {
  .corporate_view {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 450px) {
  .contact_grid {
    grid-template-columns: 1fr;
  }
}

header {
    box-sizing: border-box;
    width: 100%;
    height: 90px;
    padding: 12px;
    display: flex;
}

header > img {
    width: 150px;
}

.info_container {
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    background-color: #130F30;
    display: grid;
    grid-template-columns: 30% 50% 20%;
    justify-content: space-between;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    color: white;
}

.info_container > h1 {
    font-weight: 400;
    font-size: 18px;
    color: white;
}

.progress_container {
    display: flex;
    align-items: center;
    width: 80%;
    transition: 0.3s;
}

.progress_container > p {
    margin-left: 10px;
    font-size: 11px;
    font-weight: 300;
}

.progress_bar {
    width: 80%;
    min-width: 200px;
    height: 10px;
    background-color: #D3E0E7;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    transition: 0.3s;
}

.progress {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    height: 10px;
    background-color: #38CC5A;
    border-radius: 10px;
    transition: 0.3s;
}

.info_pdf > p {
    margin-left: 10px;
    font-size: 11px;
    font-weight: 300;
}

.info_pdf {
    cursor: pointer;
}

@media (max-width: 1080px) {
    .info_container {
        grid-template-columns: 1fr 1fr;
        height: 100px;
    }
    #title {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    #progress {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
    }
    #pdf {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
        display: flex;
        justify-content: flex-end;
    }
}

ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*, *:after, *:before {
    box-sizing: border-box;
}

.custom-select-wrapper {
    position: relative;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
    max-width: 130px;
    max-height: 30px;
    justify-self: end;
}

.custom-select {
    position: relative;
    display: flex;
    flex-direction: column;
    border-width: 0 2px 0 2px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
}
.custom-select__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    font-size: 12px;
    font-weight: 500;
    color: white;
    height: 30px;
    line-height: 60px;
    background: transparent;
    cursor: pointer;
    border-width: 2px 0 2px 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.5);
    border-radius: 1px;
}
.custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    border: 2px solid #130F30;
    border-top: 0;
    color: #ffffff;
    background: #130F30;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
}
.custom-select.open .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}
.custom-option {
    position: relative;
    display: block;
    padding: 0 22px 0 22px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s;
}
.custom-option:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #130F30;
}
.custom-option.selected {
    color: #130F30;
    background-color: #ffffff;
}

.select_caret {
    width: 10px;
    transform: rotateZ(180deg);
    transition: 0.3s;
}

.select_caret.down {
    transform: rotateZ(0deg);
}

@media (max-width: 555px) {
    .progress_container {
        width: 100%;
    }
    .info_container > h1 {
        font-size: 16px;
    }
    .progress_container {
        width: 100%;
    }
    .progress_bar {
        width: 70%;
    }
}

button {
    height: 30px;
    color: white!important;
    background-color: #FF5242;
    border: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button {
    min-width: 150px;
}

button.secondary {
    margin-top: 0;
    background-color: transparent;
    border: 1px solid #FF5242;
    color: #FF5242!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

button:disabled {
    opacity: 0.5;
}

.simple_input {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    position: relative;
}

.simple_label {
    font-size: 14px;
    font-weight: 600;
    color: #130F30;
    margin-bottom: 0px;
}

.simple_label > span {
    font-size: 12px;
    font-weight: 400;
    color: #130F30;
    margin-bottom: 0px;
    margin-left: 7px;
}

.radio_text {
    font-size: 12px;
}

.input {
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    /* height: 30px; */
    background-color: #F0F0F0;
    padding-left: 10px;
    font-size: 13px;
}

.input.div {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 10px;
}

.input.div.selected {
    border: 1px solid black;
}


.input.error {
    border: 1px solid darkred;
}

.input_error {
    font-size: 10px;
    color: #ED4337;
}

.input_error.main {
    position: absolute;
    bottom: -5px;
    left: 83%;
    font-size: 10px;
    color: #ED4337;
}

.radio_group {
    display: flex;
    flex-direction: column;
    max-width: 740px;
}

.radio_group .radio_container:not(:first-child) { margin-top: 15px; }

.radio_group_horizontal {
    display: flex;
    flex-direction: row;

    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    max-width: 740px;
}

.radio_description {
    padding-left: 35px;
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 0;
    color: #727070;
}

.feature_description {
    margin-top: 5px;
    font-size: 12px;
    color: #727070;
}

.success_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 600px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.success_container > img {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.success_container > p {
    font-size: 12px;
}


footer {
    width: 100%;
    box-sizing: border-box;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
}

footer > h4 {
    color: white;
    font-weight: 300;
    font-size: 16px;
}


.default_container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
}

@media (max-width: 1200px) {
    .default_container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 900px) {
    .rdrDateRangePickerWrapper {
        min-width: 0;
    }
    .rdrCalendarWrapper {
        min-width: 0;
        width: 80vw;
    }
    .rdrMonths {
        width: 80vw!important;
    }
    .rdrMonth {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .main_container {
        padding: 10px;
    }
    .rdrWeekDay {
        font-size: 7px!important;
    }
    .rdrDayNumber {
        font-size: 10px;
    }
    .contact_container {
        grid-template-columns: 1fr;
    }
}

.right_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.right_container > img {
    width: 70%;
}

.default_container_text {
    font-size: 12px;
    line-height: 23px;
}

.welcome_text {
    font-size: 12px;
    line-height: 20px;
    max-width: 500px;
    margin-bottom: 15px;
}

.button_container_responsive {
    display: none;
}

.image_container > img {
    width: 60%;
}

@media (max-width: 800px) {
    .default_container {
        display: flex;
        flex-direction: column-reverse;
    }
    .button_container {
        display: none;
    }
    .button_container_responsive {
        margin-top: 20px;
        display: flex;
    }
    .right_container {
        padding: 0;
    }
}


/* Switch.css */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}


.container_underline {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    height: 1px;
    border-bottom: 1px dashed rgba(114, 112, 112, 0.5);
}

.form_container {
    max-height: 1000px;
    overflow-y: auto;
}

.calendar_container {
    max-width: 340px;
}

.container_underline.full {
    width: 100%;
}

.sub_radio {
    margin-top: 10px;
    margin-left: 30px;
    color: #727070;
}

textarea {
    margin-top: 10px;
    max-width: 500px;
    min-height: 50px;
    padding-left: 10px;
    padding-top: 5px;
    width: 100%;
    border: none;
    background-color: #F0F0F0;
}

.main_form_right {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #E5E5E5;
}

@media (max-width: 1000px) {
    .main_form_right {
      display: none;
    }
}

.example_header {
    width: 100%;
    height: 25px;
    background-color: #130F30;
    color: white;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.dot {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    margin-left: 8px;
}

.example_header > p {
    margin-right: 10px;
    font-size: 14px;
}

.example_body {
    width: 100%;
    background-color: white;
    padding: 30px;
    box-sizing: border-box;
}

.top_section {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 20px;
    position: relative;
}

.example_title_container > h4 {
    font-size: 12px;
}

.mw_button {
    width: 100%;
    max-width: 128px;
    background-color: #FF5242;
    font-size: 10px;
    padding: 7px;
    color: white;
    text-transform: uppercase;
}

.mw_button.icon {
    width: 100%;
    max-width: 128px;
    background-color: #FF5242;
    font-size: 10px;
    padding: 7px;
    color: white;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mw_button.icon > img{
   height: 15px;
}


.secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    background-color: white;
    border: 1px solid #FF5242;
    color: #FF5242;
}

.example_text {
    font-size: 11px;
    line-height: 20px;
}

.example_footer {
    width: 100%;
    height: 60px;
    background-color: #130F30;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    box-sizing: border-box;
}

.example_footer_skeleton {
    height: 12px;
    background-color: rgba(39, 32, 62, 1);
    margin-bottom: 7px;
}

.hint_container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hint_dot {
    width: 13px;
    height: 13px;
    min-width: 13px;
    min-height: 13px;
    border-radius: 50%;
    margin-left: 10px;
}

.hint_container.image {
    left: -150px;
    top: 0;
}

.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    background-color: #F5F4F0;
    color: black;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    font-size: 10px;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #F5F4F0;
}

.example_logo_container {
    margin-top: 10px;
    position: relative;
}

.hint_container.logo {
    left: -60px;
    top: 25%;
}

.buttons_container {
    position: relative;
}

.hint_container.btn {
    left: -120px;
    top: 25%;
}

.hint_container.title {
    left: -100px;
    top: 10%;
}

.hint_container.merchant {
    left: -100px;
    top: 0;
}

.hint_container.conditions {
    left: -100px;
    top: 0;
}

.hint_container.additionalConditions {
    left: -160px;
    top: 0;
}

.hint_container.description {
    left: -140px;
    top: 0;
}

.hint_container.highlights {
    left: -100px;
    top: 0;
}

.hint_container.links {
    left: -70px;
    top: 0;
}

.hint_container.text {
    left: -90px;
    top: 0;
}

.hint_container.images {
    left: -150px;
    top: 0;
}

.example_title_container {
    position: relative;
}

.example_title_container.outlined {
    background-color: rgba(255, 175, 66, 0.5);
}

.example_title_container.outlined.merchant {
    background-color: rgba(237, 144, 201, 0.5);
}

.example_title_container.outlined.conditions {
    background-color: rgba(119, 224, 204, 0.5);
}

.example_title_container.outlined.additionalConditions {
    background-color: rgba(149, 136, 255, 0.5);
}

.example_title_container.outlined.description {
    background-color: rgba(157, 214, 99, 0.5);
}

.example_title_container.outlined.highlights {
    background-color: rgba(149, 136, 255, 0.5);
}

.example_title_container.outlined.text {
    background-color: rgba(237, 144, 201, 0.5);
}

.example_title_container.outlined.images {
    background-color: rgba(41, 134, 147, 0.5);
}

.example_title_container.outlined.links {
    background-color: rgba(149, 136, 255, 0.5);
}

.example_textarea_title {
    font-size: 9px;
}

.example_textarea {
    width: 90%;
    height: 60px;
    background-color: #E5E5E5;
    color: white;
}

.example_textarea > p {
   font-size: 9px;
    margin-left: 5px;
    margin-top: 5px;
}


.merchant_label {
	margin-top: 15px;
	margin-bottom: 4px;
}

.merchant_container .input_error {
	bottom: -20px;
}
.info_screen {
    margin-top: 40px;
    height: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 5px;
    position: relative;
}

.iframe-container { 
    position: relative;
     width: 100%;
     max-width: 100%;
     /* Prevents it from exceeding the parent container's width */ padding-bottom: 56.25%;
     /* 16:9 aspect ratio */ height: 0;
     flex: 1 1;
     /* Ensure it grows to take up the available space */ 
     display: flex;

     margin-top: 5px;
     margin-bottom: 5px;
} 

.iframe-container iframe { position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;

}


@media (max-width: 1000px) {
    .info_screen {
        grid-template-columns: 1fr;

    }
    .info_images {
        display: none;

    }
    .info_step > h2 {
        transform: translateY(0)!important;
    }
}

.info_content {
    padding: 20px;
}

.info_content > h1 {
    text-align: center;
    font-size: 22px;
}

.info_content_image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.info_content_image > img {
    width: 70px;
}

.info_step > h2 {
    font-size: 16px;
}

.info_step_title {
    display: flex;
    align-items: flex-start;
}

.info_step_title > div {
    width: 9px;
    height: 9px;
    min-width: 9px;
    min-height: 9px;
    background-color: #26203E;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 15px;
}

.info_step_title > h3 {
    font-size: 16px;
    margin-top: 7px;
    margin-bottom: 7px;
    line-height: 25px;
}

.info_step_sub_title {
    display: flex;
    align-items: flex-start;
    padding-left: 20px;
    margin-bottom: 7px;
}

.info_step_sub_title > div {
    width: 9px;
    height: 9px;
    min-width: 9px;
    min-height: 9px;
    background-color: white;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid #26203E;
    margin-top: 5px;
}

.info_step_sub_title > p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
}

.info_step > p {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 30px;
}

.info_back_btn {
    position: absolute;
    bottom: 0;
    left: 15px;
    width: 50px;
    cursor: pointer;
    color: rgba(19,15,58,0.4);
}

@media (max-width: 1000px) {
    .info_back_btn {
        bottom: -10px;
    }
}

.info_back_btn:hover {
    color: rgba(19,15,58,0.7);
}

.info_images {
    min-height: 730px;
    background-position: center;
    background-size: contain!important;
    background-repeat: no-repeat;
    background-color: #F4F4F4;
}

/* TOASTS */
.Toastify__toast-container {
    z-index: 9999;
    transform: translate3d(0, 0, 9999px);
    -webkit-transform: translate3d(0, 0, 9999px);
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: #fff;
}
.Toastify__toast-container--top-left {
    top: 1em;
    left: 1em;
}
.Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
    top: 100px;
    right: 1em;
}
.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em;
}
.Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em;
}

@media only screen and (max-width : 480px) {
    .Toastify__toast-container {
        width: 100vw;
        padding: 0;
        left: 0;
        margin: 0;
    }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
        top: 0;
        transform: translateX(0);
    }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
        bottom: 0;
        transform: translateX(0);
    }
    .Toastify__toast-container--rtl {
        right: 0;
        left: initial;
    }
}
.Toastify__toast {
    position: relative;
    min-height: 44px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
}
.Toastify__toast--rtl {
    direction: rtl;
}
.Toastify__toast--dark {
    background: #121212;
    color: #fff;
}
.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}
/* .Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
} */
.Toastify__toast-body {
    margin: auto 0;
    padding: 6px;
}

.Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.7s;
}

@media only screen and (max-width : 480px) {
    .Toastify__toast {
        margin-bottom: 0;
    }
}
.Toastify__close-button {
    color: #fff;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    align-self: flex-start;
}
.Toastify__close-button--default {
    color: #000;
    opacity: 0.3;
}
.Toastify__close-button > svg {
    fill: currentColor;
    height: 16px;
    width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1;
}

@keyframes Toastify__trackProgress {
    0% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}
.Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 9999;
    opacity: 0.7;
    background-color: rgba(255, 255, 255, 0.7);
    transform-origin: left;
}
.Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
    transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
}
.Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
    background: #bb86fc;
}
@keyframes Toastify__bounceInRight {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    to {
        transform: none;
    }
}
@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}
@keyframes Toastify__bounceInLeft {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0);
    }
    75% {
        transform: translate3d(-10px, 0, 0);
    }
    90% {
        transform: translate3d(5px, 0, 0);
    }
    to {
        transform: none;
    }
}
@keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }
}
@keyframes Toastify__bounceInUp {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }
    75% {
        transform: translate3d(0, 10px, 0);
    }
    90% {
        transform: translate3d(0, -5px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes Toastify__bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0);
    }
    40%, 45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}
@keyframes Toastify__bounceInDown {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }
    75% {
        transform: translate3d(0, -10px, 0);
    }
    90% {
        transform: translate3d(0, 5px, 0);
    }
    to {
        transform: none;
    }
}
@keyframes Toastify__bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0);
    }
    40%, 45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
    animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
    animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
    animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
    animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
    animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
    animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}
@keyframes Toastify__zoomOut {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
        opacity: 0;
    }
}
.Toastify__zoom-enter {
    animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
    animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
    }
    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        transform: perspective(400px);
    }
}
@keyframes Toastify__flipOut {
    from {
        transform: perspective(400px);
    }
    30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}
.Toastify__flip-enter {
    animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
    animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes Toastify__slideInLeft {
    from {
        transform: translate3d(-110%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes Toastify__slideInUp {
    from {
        transform: translate3d(0, 110%, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes Toastify__slideInDown {
    from {
        transform: translate3d(0, -110%, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes Toastify__slideOutRight {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        transform: translate3d(110%, 0, 0);
    }
}
@keyframes Toastify__slideOutLeft {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        transform: translate3d(-110%, 0, 0);
    }
}
@keyframes Toastify__slideOutDown {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        transform: translate3d(0, 500px, 0);
    }
}
@keyframes Toastify__slideOutUp {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        transform: translate3d(0, -500px, 0);
    }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
    animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
    animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
    animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
    animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
    animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
    animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
    animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
    animation-name: Toastify__slideOutDown;
}

/*# sourceMappingURL=ReactToastify.css.map */

.Toastify__toast--info {
    background: #8560FF;
    color: #ffffff;
}
.Toastify__toast--success {
    background: #41C6A2;
}
.Toastify__toast--warning {
    background: #F7C76C;
}
.Toastify__toast--error {
    background: #FF6969;
}

.footer_text {
    color: #ffffff!important;;
    text-align: center;
}

.placeholder-item {
    border-radius: 4px;
}
.loading_container {
    position: relative;
    overflow: hidden;
    background-color: #eee7e7;
    border-radius: 2px;
}

.loading_title {
    width: 100%;
}
.loading_container::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #ddcfcf 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
/* Grid layout */
.loading_box {
    margin: 20px;
    border-radius: 10px;
    padding: 20px;
}
.loading_sec-1 {
    grid-area: sec-1;
}
.loading_sec-2 {
    grid-area: sec-2;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.loading_sec-3 {
    grid-area: sec-3;
}
.loading_sec-4 {
    grid-area: sec-4;
    position: relative;
}
.loading_sec-5 {
    grid-area: sec-5;
}
.loading_sec-6 {
    grid-area: sec-6;
}
.loading_sec-7 {
    grid-area: sec-7;
}
.loading_grid_container {
    height: 1800px;
    display: grid;
    grid-template-columns: 36vw 36vw 27vw;
    grid-template-rows: auto;
    grid-template-areas:
    "sec-1 sec-2 sec-3"
    "sec-4 sec-4 sec-3"
    "sec-5 sec-5 sec-6"
    "sec-7 sec-7 sec-7";
}
.loading_chart {
    display: flex;
    position: absolute;
    left: 20px;
    bottom: 20px;
}
@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}
@media (max-width: 1150px) {
    .loading_grid_container {
        height: 2800px;
        display: grid;
        grid-template-columns: 100vw;
        grid-template-rows: auto;
        grid-template-areas:
    "sec-1"
    "sec-2"
    "sec-3"
    "sec-4"
    "sec-5"
    "sec-6"
    "sec-7";
    }
}

.table_loading {
    height: 700px;
}


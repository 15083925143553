.date_range_container {
    position: absolute;
    bottom: 50px;
    background-color: white;
    border-radius: 3px;
    padding: 10px 15px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    width: 320px;
    max-height: 400px;
}

.date_wrapper {
    max-width: 100%!important;
    max-height: 100%!important;
}

.rdrDay {
    background: #ffffff!important;
}

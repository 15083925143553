button {
    height: 30px;
    color: white!important;
    background-color: #FF5242;
    border: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button {
    min-width: 150px;
}

button.secondary {
    margin-top: 0;
    background-color: transparent;
    border: 1px solid #FF5242;
    color: #FF5242!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

button:disabled {
    opacity: 0.5;
}

.info_container {
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    background-color: #130F30;
    display: grid;
    grid-template-columns: 30% 50% 20%;
    justify-content: space-between;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    color: white;
}

.info_container > h1 {
    font-weight: 400;
    font-size: 18px;
    color: white;
}

.progress_container {
    display: flex;
    align-items: center;
    width: 80%;
    transition: 0.3s;
}

.progress_container > p {
    margin-left: 10px;
    font-size: 11px;
    font-weight: 300;
}

.progress_bar {
    width: 80%;
    min-width: 200px;
    height: 10px;
    background-color: #D3E0E7;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    transition: 0.3s;
}

.progress {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    height: 10px;
    background-color: #38CC5A;
    border-radius: 10px;
    transition: 0.3s;
}

.info_pdf > p {
    margin-left: 10px;
    font-size: 11px;
    font-weight: 300;
}

.info_pdf {
    cursor: pointer;
}

@media (max-width: 1080px) {
    .info_container {
        grid-template-columns: 1fr 1fr;
        height: 100px;
    }
    #title {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    #progress {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
    }
    #pdf {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
        display: flex;
        justify-content: flex-end;
    }
}

ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*, *:after, *:before {
    box-sizing: border-box;
}

.custom-select-wrapper {
    position: relative;
    user-select: none;
    width: 100%;
    max-width: 130px;
    max-height: 30px;
    justify-self: end;
}

.custom-select {
    position: relative;
    display: flex;
    flex-direction: column;
    border-width: 0 2px 0 2px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
}
.custom-select__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    font-size: 12px;
    font-weight: 500;
    color: white;
    height: 30px;
    line-height: 60px;
    background: transparent;
    cursor: pointer;
    border-width: 2px 0 2px 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.5);
    border-radius: 1px;
}
.custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    border: 2px solid #130F30;
    border-top: 0;
    color: #ffffff;
    background: #130F30;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
}
.custom-select.open .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}
.custom-option {
    position: relative;
    display: block;
    padding: 0 22px 0 22px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s;
}
.custom-option:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #130F30;
}
.custom-option.selected {
    color: #130F30;
    background-color: #ffffff;
}

.select_caret {
    width: 10px;
    transform: rotateZ(180deg);
    transition: 0.3s;
}

.select_caret.down {
    transform: rotateZ(0deg);
}

@media (max-width: 555px) {
    .progress_container {
        width: 100%;
    }
    .info_container > h1 {
        font-size: 16px;
    }
    .progress_container {
        width: 100%;
    }
    .progress_bar {
        width: 70%;
    }
}

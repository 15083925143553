.default_container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
}

@media (max-width: 1200px) {
    .default_container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 900px) {
    .rdrDateRangePickerWrapper {
        min-width: 0;
    }
    .rdrCalendarWrapper {
        min-width: 0;
        width: 80vw;
    }
    .rdrMonths {
        width: 80vw!important;
    }
    .rdrMonth {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .main_container {
        padding: 10px;
    }
    .rdrWeekDay {
        font-size: 7px!important;
    }
    .rdrDayNumber {
        font-size: 10px;
    }
    .contact_container {
        grid-template-columns: 1fr;
    }
}

.right_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.right_container > img {
    width: 70%;
}

.default_container_text {
    font-size: 12px;
    line-height: 23px;
}

.welcome_text {
    font-size: 12px;
    line-height: 20px;
    max-width: 500px;
    margin-bottom: 15px;
}

.button_container_responsive {
    display: none;
}

.image_container > img {
    width: 60%;
}

@media (max-width: 800px) {
    .default_container {
        display: flex;
        flex-direction: column-reverse;
    }
    .button_container {
        display: none;
    }
    .button_container_responsive {
        margin-top: 20px;
        display: flex;
    }
    .right_container {
        padding: 0;
    }
}

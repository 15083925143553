
.container_underline {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    height: 1px;
    border-bottom: 1px dashed rgba(114, 112, 112, 0.5);
}

.form_container {
    max-height: 1000px;
    overflow-y: auto;
}

.calendar_container {
    max-width: 340px;
}

.container_underline.full {
    width: 100%;
}

.sub_radio {
    margin-top: 10px;
    margin-left: 30px;
    color: #727070;
}

textarea {
    margin-top: 10px;
    max-width: 500px;
    min-height: 50px;
    padding-left: 10px;
    padding-top: 5px;
    width: 100%;
    border: none;
    background-color: #F0F0F0;
}

.main_form_right {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #E5E5E5;
}

@media (max-width: 1000px) {
    .main_form_right {
      display: none;
    }
}

.example_header {
    width: 100%;
    height: 25px;
    background-color: #130F30;
    color: white;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.dot {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    margin-left: 8px;
}

.example_header > p {
    margin-right: 10px;
    font-size: 14px;
}

.example_body {
    width: 100%;
    background-color: white;
    padding: 30px;
    box-sizing: border-box;
}

.top_section {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 20px;
    position: relative;
}

.example_title_container > h4 {
    font-size: 12px;
}

.mw_button {
    width: 100%;
    max-width: 128px;
    background-color: #FF5242;
    font-size: 10px;
    padding: 7px;
    color: white;
    text-transform: uppercase;
}

.mw_button.icon {
    width: 100%;
    max-width: 128px;
    background-color: #FF5242;
    font-size: 10px;
    padding: 7px;
    color: white;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mw_button.icon > img{
   height: 15px;
}


.secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    background-color: white;
    border: 1px solid #FF5242;
    color: #FF5242;
}

.example_text {
    font-size: 11px;
    line-height: 20px;
}

.example_footer {
    width: 100%;
    height: 60px;
    background-color: #130F30;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    box-sizing: border-box;
}

.example_footer_skeleton {
    height: 12px;
    background-color: rgba(39, 32, 62, 1);
    margin-bottom: 7px;
}

.hint_container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hint_dot {
    width: 13px;
    height: 13px;
    min-width: 13px;
    min-height: 13px;
    border-radius: 50%;
    margin-left: 10px;
}

.hint_container.image {
    left: -150px;
    top: 0;
}

.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    background-color: #F5F4F0;
    color: black;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    font-size: 10px;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #F5F4F0;
}

.example_logo_container {
    margin-top: 10px;
    position: relative;
}

.hint_container.logo {
    left: -60px;
    top: 25%;
}

.buttons_container {
    position: relative;
}

.hint_container.btn {
    left: -120px;
    top: 25%;
}

.hint_container.title {
    left: -100px;
    top: 10%;
}

.hint_container.merchant {
    left: -100px;
    top: 0;
}

.hint_container.conditions {
    left: -100px;
    top: 0;
}

.hint_container.additionalConditions {
    left: -160px;
    top: 0;
}

.hint_container.description {
    left: -140px;
    top: 0;
}

.hint_container.highlights {
    left: -100px;
    top: 0;
}

.hint_container.links {
    left: -70px;
    top: 0;
}

.hint_container.text {
    left: -90px;
    top: 0;
}

.hint_container.images {
    left: -150px;
    top: 0;
}

.example_title_container {
    position: relative;
}

.example_title_container.outlined {
    background-color: rgba(255, 175, 66, 0.5);
}

.example_title_container.outlined.merchant {
    background-color: rgba(237, 144, 201, 0.5);
}

.example_title_container.outlined.conditions {
    background-color: rgba(119, 224, 204, 0.5);
}

.example_title_container.outlined.additionalConditions {
    background-color: rgba(149, 136, 255, 0.5);
}

.example_title_container.outlined.description {
    background-color: rgba(157, 214, 99, 0.5);
}

.example_title_container.outlined.highlights {
    background-color: rgba(149, 136, 255, 0.5);
}

.example_title_container.outlined.text {
    background-color: rgba(237, 144, 201, 0.5);
}

.example_title_container.outlined.images {
    background-color: rgba(41, 134, 147, 0.5);
}

.example_title_container.outlined.links {
    background-color: rgba(149, 136, 255, 0.5);
}

.example_textarea_title {
    font-size: 9px;
}

.example_textarea {
    width: 90%;
    height: 60px;
    background-color: #E5E5E5;
    color: white;
}

.example_textarea > p {
   font-size: 9px;
    margin-left: 5px;
    margin-top: 5px;
}

